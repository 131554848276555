import React, { useRef, useState, useEffect, useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import { Flex, ModalBody, ModalFooter } from "@chakra-ui/react";
import Loading from "components/Loading";
import { Editor } from '@tinymce/tinymce-react';
import { Create2FAModalProps } from "./types";
import { useCurrentUser } from "contexts/currentUser";
import getReactAppBackendURL from "utils/env";
import Checkbox from "components/FormComponents/Checkbox";
import {
  formatDate,
  displayMonthDayYear,
  yearMonthDay,
} from "utils/dateFormats";
import { useToast } from "contexts/toast";

import UserNameIcon from "settings/theme/ChakraTheme/icons/UserNameIcon";
import MailIcon from "settings/theme/ChakraTheme/icons/MailIcon";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Input, Button, DatePicker, Textarea } from "react-rainbow-components";
import createOrganizationSchema, { CreateOrganizationSchema } from "settings/yup/schemas/createOrganizationSchema";
import create2FASchema, { Create2FASchema } from "settings/yup/schemas/create2FASchema";
import { useTranslation } from "react-i18next";
import getErrorMessage from "utils/getErrorMessage";

const backendUrl = getReactAppBackendURL();

const CreateSupportForm: React.FC<Create2FAModalProps> = ({
  hideModal,
  componentProps: { },
}) => {
  const { addToast } = useToast();

  const [user] = useCurrentUser();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [is2FaEnabledState, setIs2FaEnabledState] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  const [t] = useTranslation();

  const { errors, handleSubmit, control } = useForm<Create2FASchema>({
    resolver: yupResolver(create2FASchema),
    mode: "onBlur",
    defaultValues: {
      email_for_2fa: "",
      code: ""
    },
  });


  const handleSubmitSetupEmail = useCallback(
    (values) => {
      setIsLoading(true);
      const xhr = new XMLHttpRequest();
      const endPoint = `/setup2fa/email`;
      const link = backendUrl?.replace("/graphql", endPoint) ?? endPoint;
      xhr.open('POST', link, true);
      xhr.setRequestHeader("Content-type", "application/json; charset=UTF-8");
      xhr.responseType = 'json';

      xhr.onload = () => {
        setIsLoading(false);
        if (xhr.status >= 200 && xhr.status < 300) {
          const resp = typeof xhr.response == "string" ? JSON.parse(xhr.response) : xhr.response;
          if (resp.status === 500) {
            addToast({
              title: resp.error,
              type: "error",
            });
          } else {
            if (resp.data === 'none-verify') {
              // display a sucess toast
              addToast({
                title: `2FA Settings Updated Succesfully`,
                type: "success",
              });

              hideModal()
            } else {
              control.setValue("code", "");
              setStep(2);
            }
          }
        } else {
          addToast({
            title: xhr.statusText,
            type: "error",
          });
        }
      };

      xhr.onerror = () => {
        setIsLoading(false);
        addToast({
          title: "Error submitting 2FA Email Setup",
          type: "error",
        });
      };

      const dataToSend = JSON.stringify({
        email_for_2fa: values?.email_for_2fa ?? "",
        is_enabled: is2FaEnabledState,
        user_id: user?.id ?? 0
      });

      xhr.send(dataToSend);

    },
    [t, addToast, is2FaEnabledState],
  );

  const handleSubmitVerifyEmail = useCallback(
    (values) => {
      setIsLoading(true);
      const xhr = new XMLHttpRequest();
      const endPoint = `/setup2fa/verify`;
      const link = backendUrl?.replace("/graphql", endPoint) ?? endPoint;
      xhr.open('POST', link, true);
      xhr.setRequestHeader("Content-type", "application/json; charset=UTF-8");
      xhr.responseType = 'json';

      xhr.onload = () => {
        setIsLoading(false);
        if (xhr.status >= 200 && xhr.status < 300) {
          const resp = typeof xhr.response == "string" ? JSON.parse(xhr.response) : xhr.response;
          if (resp.status === 500) {
            addToast({
              title: resp.error,
              type: "error",
            });
          } else {

            addToast({
              title: `2FA Settings Updated Succesfully`,
              type: "success",
            });
            setTimeout(() => {
              hideModal()
            },1000)
          }
        } else {
          addToast({
            title: xhr.statusText,
            type: "error",
          });
        }
      };

      xhr.onerror = () => {
        setIsLoading(false);
        addToast({
          title: "Error submitting 2FA Email Setup",
          type: "error",
        });
      };

      
      const dataToSend = JSON.stringify({
        email_for_2fa: values?.email_for_2fa ?? "",
        is_enabled: is2FaEnabledState,
        user_id: user?.id ?? 0,
        code: values?.code ?? "",
      });

      xhr.send(dataToSend);

    },
    [t, addToast, is2FaEnabledState],
  );


  const onInvalid = useCallback(
    (values) => {
      console.log("Here invlid form fields:", values);
      addToast({
        title: "Form Error: Invalid field",
        type: "error",
      });
    },
    [t, addToast]
  )

  // load the users current 2fa setup
  const loadUsersCurrent2fa = async () => {
    setIsLoading(true);
    const xhr = new XMLHttpRequest();
    const endPoint = `/setup2fa/${user?.id}`;
    const link = backendUrl?.replace("/graphql", endPoint) ?? endPoint;
    xhr.open('GET', link, true);
    xhr.setRequestHeader("Content-type", "application/json; charset=UTF-8");
    xhr.responseType = 'json';

    xhr.onload = () => {
      setIsLoading(false);
      if (xhr.status >= 200 && xhr.status < 300) {
        const resp = typeof xhr.response == "string" ? JSON.parse(xhr.response) : xhr.response;
        // console.log("resp:", resp);
        control.setValue("email_for_2fa", resp.data.email_for_2fa);
        setIs2FaEnabledState(resp.data.is_enabled);
      } else {
        console.log("Error", xhr.statusText);
        addToast({
          title: "Form Error: Failed to loading 2FA setup: " + xhr.statusText,
          type: "error",
        });
      }
    };

    xhr.onerror = () => {
      addToast({
        title: "Form Error: Failed to load current 2FA setup",
        type: "error",
      });
    };

    xhr.send();
  }

  useEffect(() => {
    //clear error message
    setErrorMessage("");
    // clear all the chat history
    loadUsersCurrent2fa();
  }, [user]);


  return (
    <VStack  >
      <ModalBody as="form" width="full" >
        <Flex flexDirection={"column"} gap={4}  >

          {step === 1 &&
            <p style={{ fontSize: "14px", paddingBottom: "20px" }}>
              If its the first time setting up 2FA( Two Factor Authentication), an email verification code will be sent your 2fa email, if its different than your account email
            </p>
          }

          {step === 2 &&
            <p style={{ fontSize: "14px", paddingBottom: "20px" }}>
              A five digit verification code has been sent to the submitted email.
            </p>
          }

          <div style={{ paddingLeft: "20px" }}>
            
            <Checkbox
              name="is_enabled"
              isChecked={is2FaEnabledState}
              onChange={(e) => setIs2FaEnabledState(!is2FaEnabledState) }
              isDisabled={step === 2}
            >
              Is 2FA Enabled
            </Checkbox>
          </div>


          <Controller
            as={Input}
            control={control}
            name="email_for_2fa"
            type="email"
            required
            icon={<MailIcon />}
            disabled={step === 2}
            label={"Two Factor Authentication Email"}
            placeholder={"Provide an email used for 2fa"}
            className="sign-up-input-field"
            error={getErrorMessage("email_for_2fa", errors)}
            iconPosition="right"
            labelAlignment="left"
          />


          {step === 2 &&
            <Controller
              as={Input}
              control={control}
              name="code"
              type="text"
              label={"Verification Code"}
              required
              placeholder={"Provide the 2fa email verification code"}
              className="sign-up-input-field"
              error={getErrorMessage("code", errors)}
              iconPosition="right"
              labelAlignment="left"
              maxLength={5}
              minLength={5}
            />
          }

          <div className="sign-up-actions-container" style={{ width: "100%", marginTop: "30px" }}>
            {step === 1 &&
              <Button
                label={"Setup 2FA Email"}
                variant="brand"
                type="submit"
                onClick={handleSubmit(handleSubmitSetupEmail, onInvalid)}
                isLoading={isLoading}
                style={{ width: "100%" }}
              />
            }

            {step === 2 &&
              <Button
                label={"Verify Email"}
                variant="brand"
                type="submit"
                onClick={handleSubmit(handleSubmitVerifyEmail, onInvalid)}
                isLoading={isLoading}
                style={{ width: "100%" }}
              />
            }
          </div>
        </Flex>
      </ModalBody>
    </VStack>
  );
};

export default CreateSupportForm;
