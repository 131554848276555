import yup from "settings/yup";

const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
  code: yup.string().default(""),
});

export interface LoginSchema {
  email: string;
  password: string;
  code: string
}

export default loginSchema;
