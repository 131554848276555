import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Input, Button } from "react-rainbow-components";

import getErrorMessage from "utils/getErrorMessage";
import CustomCard from "components/CustomCard";
import useSession from "hooks/useSession";
import loginSchema, { LoginSchema } from "settings/yup/schemas/loginSchema";
import login2FASchema, { Login2FASchema } from "settings/yup/schemas/login2FASchema";
import { useToast } from "contexts/toast";
import MailIcon from "settings/theme/ChakraTheme/icons/MailIcon";
import LockIcon from "settings/theme/ChakraTheme/icons/LockIcon";
import { useLoginUserMutation } from "generated/graphql";
import { pages } from "routes/pages";

const Login: React.FC = () => {
  const [createSession] = useSession();
  const [t] = useTranslation();
  const { addToast } = useToast();

  const [show2FaForm, setShow2FaForm] = useState<boolean>(false);
  const [carriedValuesFor2FA, setCarriedValuesFor2FA] = useState<any>({
    email: "",
    password: "",
  });

  const { errors, handleSubmit, control } = useForm<LoginSchema>({
    resolver: yupResolver(loginSchema),
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
      code: ""
    },
  });

  const the2FaFormControl = useForm<Login2FASchema>({
    resolver: yupResolver(login2FASchema),
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
      code: ""
    },
  });

  const [loginUser, { loading }] = useLoginUserMutation();

  const handleLogin = (values: any) => {
    let password = values.password;

    if (show2FaForm === true) {
      password = `_2fa_${values.code}_2fa_${password}`
    }

    loginUser({
      variables: {
        password: password,
        email: values.email,
      },
    })
      .then((response) => {
        const token = response?.data?.loginUser;
        if (token === '2fa') {
          setCarriedValuesFor2FA({
            password: password,
            email: values.email,
          });
          setShow2FaForm(true);
        } else if (token) {
          createSession(token, pages.dashboard.pathWithParams());
        } else {
          addToast({
            title: "Unspecified login process error",
            type: "error",
          });
        }
      })
      .catch((error) => {
        addToast({
          title: error?.message,
          type: "error",
        });
      });
  }

  const onSubmit = useCallback(
    (values) => {
      handleLogin(values);
    },
    [addToast, loginUser, createSession],
  );

  const onSubmit2FA = useCallback(
    (values) => {
      handleLogin(
        {
          ...values,
          ...carriedValuesFor2FA
        }
      );
    },
    [addToast, loginUser, createSession, show2FaForm]
  );

  return (
    <form className="login-wrapper">
      {show2FaForm == false &&
        <CustomCard
          title={t("login.title")}
          shouldAnimate
          shouldShowLogo
          subtitle={
            <span>
              {/* <Link to={pages.signUp.pathWithParams()}>
              {t("login.create_account_here")}
            </Link> */}
            </span>
          }
        >
          <Controller
            as={Input}
            control={control}
            name="email"
            type="email"
            required
            icon={<MailIcon />}
            label={t("login.email_address")}
            placeholder={t("login.enter_your_email_address")}
            className="login-input-field"
            error={getErrorMessage("email", errors)}
            iconPosition="right"
            labelAlignment="left"

          />

          <Controller
            as={Input}
            control={control}
            name="password"
            type="password"
            required
            icon={<LockIcon />}
            label={t("login.password")}
            placeholder={t("login.enter_your_password")}
            className="login-input-field"
            error={getErrorMessage("password", errors)}
            iconPosition="right"
            labelAlignment="left"
          />

          <div className="login-actions-container">
            <Button
              label={t("login.login")}
              variant="brand"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isLoading={loading}
            />

            <Link to={pages.forgotPassword.pathWithParams()}>
              {t("login.forgot_your_password")}
            </Link>
          </div>
        </CustomCard>
      }

      {show2FaForm == true &&
        <CustomCard
          title={t("login.title") + " - Two Factor Authentication"}
          shouldAnimate
          shouldShowLogo
          subtitle={
            <span>
              {/* <Link to={pages.signUp.pathWithParams()}>
              {t("login.create_account_here")}
            </Link> */}
            </span>
          }
        >
          <Controller
            as={Input}
            control={the2FaFormControl.control}
            name="code"
            type="text"
            required
            label={"2FA Code"}
            placeholder={"Enter the 2FA code sent to"}
            className="login-input-field"
            error={getErrorMessage("code", the2FaFormControl.errors)}
            iconPosition="right"
            labelAlignment="left"
          />



          <div className="login-actions-container" style={{ marginTop: "100px" }}>
            <Button
              label={"Verify To " + t("login.login")}
              variant="brand"
              type="submit"
              onClick={the2FaFormControl.handleSubmit(onSubmit2FA)}
              isLoading={loading}
            />
          </div>
        </CustomCard>
      }
    </form>
  );
};

export default Login;
