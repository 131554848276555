import { Flex } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useModal } from "contexts/modal";

import LogoIcon from "settings/theme/ChakraTheme/icons/LogoIcon";
import AdminImpersonation from "components/AdminImpersonation";

import HeaderLink from "./HeaderLink";
import HeaderMenu from "./HeaderMenu";
import { HeaderProps } from "./types";
import { pages } from "routes/pages";
import { useCurrentUser } from "contexts/currentUser";

import { ButtonMenu, MenuItem } from "react-rainbow-components";
import HeaderExternalLink from "./HeaderExternalLink";
import getReactAppBackendURL from "utils/env";
import CreateOrganisationModal from "components/CreateOrganisationForm";
import Create2FASupportForm from "components/Create2FASupportForm";
import CreateSupportForm from "components/CreateSupportForm";
import MenuDropDownIcon from "./MenuDropDownIcon";

const backendUrl = getReactAppBackendURL(); //process?.env?.REACT_APP_BACKEND_URL;


const Header: React.FC<HeaderProps> = ({ showNavItems }) => {
  const [t] = useTranslation();
  const [user] = useCurrentUser();
  const [showModal] = useModal();
  const [showSupportModal] = useModal();
  const [show2FAModal] = useModal();


  const openCreateOrganisationModal = useCallback(() => {
    showModal({
      title: "Create Organization",
      component: CreateOrganisationModal,
      componentProps: {},
      closeOnOverlayClick: false
    });
  }, [t, showModal]);

  const openSupportFormModal = useCallback(() => {
    showSupportModal({
      title: "Support Ticket Form",
      component: CreateSupportForm,
      componentProps: {
      },
      closeOnOverlayClick: false
    });
  }, [t, showSupportModal]);

  const open2FAFormModal = useCallback(() => {
    show2FAModal({
      title: "Setup 2FA Login",
      component: Create2FASupportForm,
      componentProps: {
      },
      closeOnOverlayClick: true
    });
  }, [t, show2FAModal]);

  return (
    <nav className="header header-wrapper hide-on-print">
      <Link to={pages.dashboard.pathWithParams()} style={{paddingLeft: "12px"}}>
        <LogoIcon boxSize="80px" />
      </Link>

      <div className="links-wrapper">
        {showNavItems && (
          <>
            <HeaderLink
              to={pages.ulysses.pathWithParams()}
              title={t("header.ulysses")}
            />
            <HeaderLink
              to={pages.dashboard.pathWithParams()}
              title={t("header.dashboard")}
            />
            <HeaderLink
              to={pages.programs.pathWithParams()}
              title={t("header.programs")}
            />
            {/* forces a default status of did not apply */}
            <HeaderLink
              to={pages.grantFlow.pathWithParams({
                grantStatus: "NOT_APPLY",
              })}
              title={t("header.grant_flow")}
            />
            <HeaderLink
              to={pages.deadlines.pathWithParams()}
              title={t("header.deadlines")}
            />
            <HeaderLink
              to={pages.reports.pathWithParams()}
              title={t("header.report_center")}
            />
            <HeaderLink
              to={pages.fundersList.pathWithParams()}
              title={t("header.funders")}
            />

            <ButtonMenu
              buttonVariant="base"
              icon={<MenuDropDownIcon />}
              menuSize="small"
              buttonSize="large"
              menuAlignment="center"
              assistiveText={"Misc./ Help"}
              className="menu-dropdown-button"
            >

              <div className="menu-item-link">
                <HeaderLink
                  to={pages.largestFunders.pathWithParams()}
                  title={t("header.largest_funders")}
                />
              </div>
              
              <MenuItem label={"Setup 2FA Login"} onClick={open2FAFormModal} />

              <MenuItem label={"Help/ Support"} onClick={openSupportFormModal} />

              {(user?.role === "ADMIN" || user?.role === "MANAGER") && (
                <MenuItem label={"Add Organization"} onClick={openCreateOrganisationModal} />
              )}

            </ButtonMenu>

            {user?.role === "ADMIN" && (
              <HeaderExternalLink
                href={`${backendUrl}/../admin`}
                label={t("header.admin")}
              />
            )}

          </>
        )}
      </div>

      <Flex alignItems="center">
        <AdminImpersonation />

        <HeaderMenu />
      </Flex>
    </nav>
  );
};

export default Header;
