/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
    [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
  };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
   */
  Date: { input: string; output: string };
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: { input: string; output: string };
  /**
   * The `Decimal` scalar type represents signed double-precision fractional
   * values parsed by the `Decimal` library.  The Decimal appears in a JSON
   * response as a string to preserve precision.
   */
  Decimal: { input: string; output: string };
  /** Represents an uploaded file. */
  Upload: { input: any; output: any };
};

/** An application */
export type Application = {
  __typename?: "Application";
  amountAwarded?: Maybe<Scalars["Decimal"]["output"]>;
  amountRequested?: Maybe<Scalars["Decimal"]["output"]>;
  award?: Maybe<Award>;
  deadline: Scalars["Date"]["output"];
  deadlineType: DeadlineTypeEnum;
  declined: Scalars["Boolean"]["output"];
  declinedAt?: Maybe<Scalars["Date"]["output"]>;
  declinedDetails?: Maybe<DeclinedDetails>;
  declinedReason?: Maybe<Scalars["String"]["output"]>;
  fiscalDate: Scalars["Date"]["output"];
  grant: Grant;
  id: Scalars["Int"]["output"];
  ineligibleReason?: Maybe<Scalars["String"]["output"]>;
  isSubmittedAsIneligible: Scalars["Boolean"]["output"];
  receivedAt?: Maybe<Scalars["Date"]["output"]>;
  reports: Array<Report>;
  status: ApplicationStatusEnum;
  submission?: Maybe<Submission>;
  submittedAt?: Maybe<Scalars["Date"]["output"]>;
};

/** The application status enum */
export type ApplicationStatusEnum =
  /** Awarded */
  | "AWARDED"
  /** Declined */
  | "DECLINED"
  /** Did not respond */
  | "DID_NOT_RESPOND"
  /** Ineligible */
  | "INELIGIBLE"
  /** Did not apply */
  | "NOT_APPLY"
  /** Pending */
  | "PENDING";

/** An award */
export type Award = {
  __typename?: "Award";
  amountAwarded: Scalars["Decimal"]["output"];
  id: Scalars["Int"]["output"];
  isPrecisionByYear: Scalars["Boolean"]["output"];
  receivementDate: Scalars["Date"]["output"];
  responseDays?: Maybe<Scalars["Int"]["output"]>;
};

/** Award by year */
export type AwardByYear = {
  __typename?: "AwardByYear";
  awarded: Scalars["Decimal"]["output"];
  awardedCount: Scalars["Int"]["output"];
  pending: Scalars["Decimal"]["output"];
  pendingCount: Scalars["Int"]["output"];
  requested: Scalars["Decimal"]["output"];
  requestedCount: Scalars["Int"]["output"];
  /** @deprecated Use `awarded` instead */
  total: Scalars["Decimal"]["output"];
  year: Scalars["Int"]["output"];
};

/** Available parameters to create an application */
export type CreateApplicationParams = {
  award?: InputMaybe<CreateAwardParams>;
  deadline: Scalars["Date"]["input"];
  deadlineType: DeadlineTypeEnum;
  declined?: InputMaybe<Scalars["Boolean"]["input"]>;
  declinedDetails?: InputMaybe<CreateDeclinedParams>;
  ineligibleReason?: InputMaybe<Scalars["String"]["input"]>;
  isSubmittedAsIneligible?: InputMaybe<Scalars["Boolean"]["input"]>;
  notApply?: InputMaybe<Scalars["Boolean"]["input"]>;
  reports?: InputMaybe<Array<CreateReportParams>>;
  submission?: InputMaybe<CreateSubmissionParams>;
};

/** Available parameters to create an award */
export type CreateAwardParams = {
  amountAwarded: Scalars["Decimal"]["input"];
  receivementDate: Scalars["Date"]["input"];
};

/** Available parameters to create when decline */
export type CreateDeclinedParams = {
  declinedDate?: InputMaybe<Scalars["Date"]["input"]>;
  declinedReason?: InputMaybe<Scalars["String"]["input"]>;
};

/** Available fields to create a funder */
export type CreateFunderParams = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  howToApply?: InputMaybe<Scalars["String"]["input"]>;
  isPaused?: InputMaybe<Scalars["Boolean"]["input"]>;
  keyContacts?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<CreateLocationParams>;
  name: Scalars["String"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  organizationId: Scalars["Int"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
  type: FunderTypeEnum;
  website?: InputMaybe<Scalars["String"]["input"]>;
};

/** Available parameters to create a grant */
export type CreateGrantParams = {
  application: CreateApplicationParams;
  frequency: GrantFrequencyEnum;
  funderId: Scalars["Int"]["input"];
  programId: Scalars["Int"]["input"];
  recurrenceCount?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Available parameters to create a location */
export type CreateLocationParams = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  complement?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
  zipcode?: InputMaybe<Scalars["String"]["input"]>;
};

/** Available fields to create an organization */
export type CreateOrganizationParams = {
  fiscalYearStart: Scalars["Date"]["input"];
  name: Scalars["String"]["input"];
};

/** Available fields to create a program */
export type CreateProgramParams = {
  name: Scalars["String"]["input"];
  organizationId: Scalars["Int"]["input"];
  yearlyGoal?: InputMaybe<Scalars["Decimal"]["input"]>;
};

/** Available parameters to create a report */
export type CreateReportParams = {
  deadline: Scalars["Date"]["input"];
  submissionDate?: InputMaybe<Scalars["Date"]["input"]>;
  urgency: ReportUrgencyEnum;
};

/** Available parameters to create a submission */
export type CreateSubmissionParams = {
  amountRequested: Scalars["Decimal"]["input"];
  submissionDate: Scalars["Date"]["input"];
};

/** The current year goal */
export type CurrentYearGoal = {
  __typename?: "CurrentYearGoal";
  overall: OverallYearGoal;
  programs: Array<Program>;
};

/** The application deadline type enum */
export type DeadlineTypeEnum =
  /** Required */
  | "REQUIRED"
  /** Rolling */
  | "ROLLING";

/** A Declined details */
export type DeclinedDetails = {
  __typename?: "DeclinedDetails";
  declinedDate: Scalars["Date"]["output"];
  declinedReason?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
};

/** The features of a product */
export type Features = {
  __typename?: "Features";
  aiIntegration: Scalars["Boolean"]["output"];
  userCount: Scalars["Int"]["output"];
};

/** A file */
export type File = {
  __typename?: "File";
  blobName: Scalars["String"]["output"];
  folder: Scalars["String"]["output"];
  funder?: Maybe<Funder>;
  id: Scalars["Int"]["output"];
  insertedAt: Scalars["DateTime"]["output"];
  name: Scalars["String"]["output"];
  signedUrl: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

/** Available file filters */
export type FileFilter = {
  funderId: Scalars["Int"]["input"];
  inFolder?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Available parameters to filter applications */
export type FilterApplicationParams = {
  /** Should not be sent with the 'year' field defined */
  endAt?: InputMaybe<Scalars["Date"]["input"]>;
  funderId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId: Scalars["Int"]["input"];
  search?: InputMaybe<Scalars["String"]["input"]>;
  /** Should not be sent with the 'year' field defined */
  startAt?: InputMaybe<Scalars["Date"]["input"]>;
  status?: InputMaybe<FilterApplicationStatusEnum>;
  /** It should not be sent with the 'startAt' or 'endAt' fields defined */
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The enum filter for the application status */
export type FilterApplicationStatusEnum =
  /** Awarded */
  | "AWARDED"
  /** Declined */
  | "DECLINED"
  /** Did not respond */
  | "DID_NOT_RESPOND"
  /** Ineligible */
  | "INELIGIBLE"
  /** Did not apply */
  | "NOT_APPLY"
  /** Pending */
  | "PENDING"
  /** Submitted */
  | "SUBMITTED";

/** Available parameters to filter a funder */
export type FilterFunderParams = {
  organizationId: Scalars["Int"]["input"];
  search?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<FunderTypeEnum>;
};

/** Available parameters to filter a report */
export type FilterReportParams = {
  endAt?: InputMaybe<Scalars["Date"]["input"]>;
  organizationId: Scalars["Int"]["input"];
  search?: InputMaybe<Scalars["String"]["input"]>;
  startAt?: InputMaybe<Scalars["Date"]["input"]>;
  submitted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A folder that contains files */
export type Folder = {
  __typename?: "Folder";
  children: Array<FolderContents>;
  funderId: Scalars["Int"]["output"];
  insertedAt: Scalars["DateTime"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

/** A folder that contains files */
export type FolderChildrenArgs = {
  search?: InputMaybe<Scalars["String"]["input"]>;
};

/** Files or folders that can be found in a folder */
export type FolderContents = File | Folder;

export type FolderFilter = {
  funderId: Scalars["Int"]["input"];
  inFolder?: InputMaybe<Scalars["String"]["input"]>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

/** A funder */
export type Funder = {
  __typename?: "Funder";
  awards: Array<Award>;
  email?: Maybe<Scalars["String"]["output"]>;
  grants: Array<Grant>;
  howToApply?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isPaused: Scalars["Boolean"]["output"];
  complexityScore?: Maybe<Scalars["String"]["output"]>;
  keyContacts?: Maybe<Scalars["String"]["output"]>;
  lastAward?: Maybe<Award>;
  location?: Maybe<Location>;
  name: Scalars["String"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  organization: Organization;
  phone?: Maybe<Scalars["String"]["output"]>;
  totalAwardAmount?: Maybe<Scalars["Decimal"]["output"]>;
  type: FunderTypeEnum;
  website?: Maybe<Scalars["String"]["output"]>;
};

/** A funder */
export type FunderTotalAwardAmountArgs = {
  endAt?: InputMaybe<Scalars["Date"]["input"]>;
  startAt?: InputMaybe<Scalars["Date"]["input"]>;
};

/** The funder type enum */
export type FunderTypeEnum =
  /** Corporate */
  | "CORPORATE"
  /** Foundation */
  | "FOUNDATION"
  /** Government */
  | "GOVERNMENT"
  /** Organization */
  | "ORGANIZATION"
  /** Other */
  | "OTHER";

/** The funder types count */
export type FunderTypesCount = {
  __typename?: "FunderTypesCount";
  corporate: Scalars["Int"]["output"];
  foundation: Scalars["Int"]["output"];
  government: Scalars["Int"]["output"];
  organization: Scalars["Int"]["output"];
  other: Scalars["Int"]["output"];
};

/** A grant */
export type Grant = {
  __typename?: "Grant";
  applications: Array<Application>;
  frequency: GrantFrequencyEnum;
  funder: Funder;
  id: Scalars["Int"]["output"];
  program: Program;
  responseAverage?: Maybe<Scalars["Int"]["output"]>;
};

/** Possible values for the grant frequency */
export type GrantFrequencyEnum =
  /** One Time */
  | "ONE_TIME"
  /** Recurring */
  | "RECURRING";

/** The largest funders object */
export type LargestFunders = {
  __typename?: "LargestFunders";
  overall: Array<Funder>;
  programsFunders: Array<ProgramsFunders>;
};

/** A location */
export type Location = {
  __typename?: "Location";
  city?: Maybe<Scalars["String"]["output"]>;
  complement?: Maybe<Scalars["String"]["output"]>;
  funder?: Maybe<Funder>;
  id?: Maybe<Scalars["Int"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  streetAddress?: Maybe<Scalars["String"]["output"]>;
  zipcode?: Maybe<Scalars["String"]["output"]>;
};

/** An organization */
export type Organization = {
  __typename?: "Organization";
  awardsByYear: Array<AwardByYear>;
  currentFiscalYear: Scalars["Int"]["output"];
  currentYearGoal: CurrentYearGoal;
  firstFiscalYear: Scalars["Int"]["output"];
  fiscalYearStart: Scalars["Date"]["output"];
  funders: Array<Funder>;
  funderTypesCount: FunderTypesCount;
  id: Scalars["Int"]["output"];
  largestFunders: LargestFunders;
  name: Scalars["String"]["output"];
  oldestApplication?: Maybe<Application>;
  oldestAward?: Maybe<Award>;
  programs: Array<Program>;
  subscription?: Maybe<Subscription>;
  user?: Maybe<User>;
  yearlyGoal: Scalars["Decimal"]["output"];
};

/** An organization */
export type OrganizationAwardsByYearArgs = {
  fiscalYears?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

/** An organization */
export type OrganizationFunderTypesCountArgs = {
  fiscalYear?: InputMaybe<Scalars["Int"]["input"]>;
};

/** An organization */
export type OrganizationLargestFundersArgs = {
  endAt?: InputMaybe<Scalars["Date"]["input"]>;
  startAt?: InputMaybe<Scalars["Date"]["input"]>;
  type?: InputMaybe<FunderTypeEnum>;
};

/** An organization */
export type OrganizationYearlyGoalArgs = {
  fiscalYear: Scalars["Int"]["input"];
};

/** The overall year goal */
export type OverallYearGoal = {
  __typename?: "OverallYearGoal";
  achieved: Scalars["Decimal"]["output"];
  achievedRate: Scalars["Decimal"]["output"];
  goal: Scalars["Decimal"]["output"];
  missing: Scalars["Decimal"]["output"];
};

export type PaginationParams = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  sortingOrder?: InputMaybe<SortingOrder>;
};

/** A price */
export type Price = {
  __typename?: "Price";
  currency: Scalars["String"]["output"];
  product: Product;
  recurringInterval: PriceIntervalEnum;
  unitAmount: Scalars["Decimal"]["output"];
};

/** Possible values for the price interval */
export type PriceIntervalEnum =
  /** Monthly */
  | "MONTH"
  /** Yearly */
  | "YEAR";

/** A product */
export type Product = {
  __typename?: "Product";
  description: Scalars["String"]["output"];
  features: Features;
  name: Scalars["String"]["output"];
  prices?: Maybe<Array<Maybe<Price>>>;
  tier: ProductTierEnum;
};

/** Possible values for a product tier */
export type ProductTierEnum =
  /** Basic */
  | "BASIC"
  /** Premium */
  | "PREMIUM";

/** A program */
export type Program = {
  __typename?: "Program";
  achievedAmount: Scalars["Decimal"]["output"];
  funders: Array<Funder>;
  grants: Array<Grant>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  organization: Organization;
  startFy?: Maybe<Scalars["Int"]["output"]>;
  yearlyGoal: Scalars["Decimal"]["output"];
};

/** A program */
export type ProgramAchievedAmountArgs = {
  fiscalYear: Scalars["Int"]["input"];
};

/** A program */
export type ProgramYearlyGoalArgs = {
  fiscalYear: Scalars["Int"]["input"];
};

/** A Program Funder */
export type ProgramFunder = {
  __typename?: "ProgramFunder";
  awardedAmount: Scalars["Decimal"]["output"];
  funder: Funder;
};

/** A list of programs and their funders */
export type ProgramsFunders = {
  __typename?: "ProgramsFunders";
  funders: Array<ProgramFunder>;
  program: Program;
};

/** A report */
export type Report = {
  __typename?: "Report";
  deadline: Scalars["Date"]["output"];
  grantApplication: Application;
  id: Scalars["Int"]["output"];
  submissionDate?: Maybe<Scalars["Date"]["output"]>;
  submitted: Scalars["Boolean"]["output"];
  urgency: ReportUrgencyEnum;
};

/** Possible values for the report urgency */
export type ReportUrgencyEnum =
  /** Courtesy */
  | "COURTESY"
  /** Required */
  | "REQUIRED";

export type RootMutationType = {
  __typename?: "RootMutationType";
  createFolder?: Maybe<Folder>;
  /** Create a funder */
  createFunder?: Maybe<Funder>;
  /** Create a grant */
  createGrant?: Maybe<Grant>;
  /** Create a program */
  createProgram?: Maybe<Program>;
  /** Creates an unverified user with unconfirmed email */
  createUnverifiedUser?: Maybe<Scalars["String"]["output"]>;
  /** Delete an application */
  deleteApplication?: Maybe<Application>;
  deleteFile?: Maybe<File>;
  deleteFolder: Array<File>;
  /** Delete a funder */
  deleteFunder?: Maybe<Funder>;
  /** Delete a grant */
  deleteGrant?: Maybe<Grant>;
  /** Delete a program */
  deleteProgram?: Maybe<Program>;
  /** Logins an user */
  loginUser?: Maybe<Scalars["String"]["output"]>;
  /** Sends an email to reset password */
  requestResetPassword?: Maybe<Scalars["String"]["output"]>;
  /** Resets the user's password */
  resetPassword?: Maybe<Scalars["String"]["output"]>;
  /** Update an application */
  updateApplication?: Maybe<Application>;
  updateFile?: Maybe<File>;
  /** Update a funder */
  updateFunder?: Maybe<Funder>;
  /** Update an organization */
  updateOrganization?: Maybe<Organization>;
  /** Update a program */
  updateProgram?: Maybe<Program>;
  /** Update a program's yearly goal */
  updateProgramYearlyGoal?: Maybe<Program>;
  /** Update a report */
  updateReport?: Maybe<Report>;
  uploadFile?: Maybe<File>;
};

export type RootMutationTypeCreateFolderArgs = {
  folder: Scalars["String"]["input"];
  funderId: Scalars["Int"]["input"];
};

export type RootMutationTypeCreateFunderArgs = {
  params: CreateFunderParams;
};

export type RootMutationTypeCreateGrantArgs = {
  params: CreateGrantParams;
};

export type RootMutationTypeCreateProgramArgs = {
  params: CreateProgramParams;
};

export type RootMutationTypeCreateUnverifiedUserArgs = {
  email: Scalars["String"]["input"];
  organization: CreateOrganizationParams;
  password: Scalars["String"]["input"];
};

export type RootMutationTypeDeleteApplicationArgs = {
  id: Scalars["Int"]["input"];
};

export type RootMutationTypeDeleteFileArgs = {
  id: Scalars["Int"]["input"];
};

export type RootMutationTypeDeleteFolderArgs = {
  folder: Scalars["String"]["input"];
  funderId: Scalars["Int"]["input"];
};

export type RootMutationTypeDeleteFunderArgs = {
  id: Scalars["Int"]["input"];
};

export type RootMutationTypeDeleteGrantArgs = {
  id: Scalars["Int"]["input"];
};

export type RootMutationTypeDeleteProgramArgs = {
  id: Scalars["Int"]["input"];
};

export type RootMutationTypeLoginUserArgs = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type RootMutationTypeRequestResetPasswordArgs = {
  email: Scalars["String"]["input"];
};

export type RootMutationTypeResetPasswordArgs = {
  password: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type RootMutationTypeUpdateApplicationArgs = {
  id: Scalars["Int"]["input"];
  params: UpdateApplicationParams;
};

export type RootMutationTypeUpdateFileArgs = {
  id: Scalars["Int"]["input"];
  params: UpdateFile;
};

export type RootMutationTypeUpdateFunderArgs = {
  id: Scalars["Int"]["input"];
  params: UpdateFunderParams;
};

export type RootMutationTypeUpdateOrganizationArgs = {
  id: Scalars["Int"]["input"];
  params: UpdateOrganizationParams;
};

export type RootMutationTypeUpdateProgramArgs = {
  id: Scalars["Int"]["input"];
  params: UpdateProgramParams;
};

export type RootMutationTypeUpdateProgramYearlyGoalArgs = {
  id: Scalars["Int"]["input"];
  params: UpdateProgramYearlyGoalParams;
};

export type RootMutationTypeUpdateReportArgs = {
  id: Scalars["Int"]["input"];
  params: UpdateReportParams;
};

export type RootMutationTypeUploadFileArgs = {
  params: UploadFile;
};

export type RootQueryType = {
  __typename?: "RootQueryType";
  /** Gets the user associated with the given token */
  currentUser?: Maybe<User>;
  /** Gets an application by ID */
  getApplication?: Maybe<Application>;
  getFolder?: Maybe<Folder>;
  /** Gets a funder by ID */
  getFunder?: Maybe<Funder>;
  /** Gets an organization by ID */
  getOrganization: Organization;
  /** Get a product */
  getProduct?: Maybe<Product>;
  /** Gets a program by ID */
  getProgram?: Maybe<Program>;
  /** Gets a report by ID */
  getReport?: Maybe<Report>;
  /** Gets a user by id */
  getUser?: Maybe<User>;
  /** Gets a list of applications */
  listApplications?: Maybe<Array<Application>>;
  listFiles?: Maybe<Array<File>>;
  listFolders?: Maybe<Array<Folder>>;
  /** Gets a list of all funders */
  listFunders?: Maybe<Array<Funder>>;
  /** Gets a list of all organizations */
  listOrganizations?: Maybe<Array<Organization>>;
  /** Get all products */
  listProducts?: Maybe<Array<Maybe<Product>>>;
  /** Gets a list of all programs in an organization */
  listPrograms?: Maybe<Array<Program>>;
  /** List all reports with filters */
  listReports?: Maybe<Array<Report>>;
  /** Gets a list of all users */
  listUsers?: Maybe<Array<User>>;
  /** Gets the application version */
  version?: Maybe<Scalars["String"]["output"]>;
};

export type RootQueryTypeGetApplicationArgs = {
  id: Scalars["Int"]["input"];
};

export type RootQueryTypeGetFolderArgs = {
  folder: Scalars["String"]["input"];
  funderId: Scalars["Int"]["input"];
};

export type RootQueryTypeGetFunderArgs = {
  id: Scalars["Int"]["input"];
};

export type RootQueryTypeGetOrganizationArgs = {
  id: Scalars["Int"]["input"];
};

export type RootQueryTypeGetProductArgs = {
  id: Scalars["Int"]["input"];
};

export type RootQueryTypeGetProgramArgs = {
  id: Scalars["Int"]["input"];
};

export type RootQueryTypeGetReportArgs = {
  id: Scalars["Int"]["input"];
};

export type RootQueryTypeGetUserArgs = {
  id: Scalars["Int"]["input"];
};

export type RootQueryTypeListApplicationsArgs = {
  filters: FilterApplicationParams;
  pagination?: InputMaybe<PaginationParams>;
};

export type RootQueryTypeListFilesArgs = {
  filters: FileFilter;
};

export type RootQueryTypeListFoldersArgs = {
  filters: FolderFilter;
};

export type RootQueryTypeListFundersArgs = {
  filters: FilterFunderParams;
};

export type RootQueryTypeListProgramsArgs = {
  organizationId: Scalars["Int"]["input"];
};

export type RootQueryTypeListReportsArgs = {
  filters: FilterReportParams;
  pagination?: InputMaybe<PaginationParams>;
};

/** Sorting order */
export type SortingOrder =
  /** Ascending order */
  | "ASC"
  /** Descending order */
  | "DESC";

/** A submission */
export type Submission = {
  __typename?: "Submission";
  amountRequested: Scalars["Decimal"]["output"];
  id: Scalars["Int"]["output"];
  submissionDate: Scalars["Date"]["output"];
};

/** A subscription */
export type Subscription = {
  __typename?: "Subscription";
  cancelAtPeriodEnd: Scalars["Boolean"]["output"];
  currentPeriodEnd: Scalars["Date"]["output"];
  organization: Organization;
  price: Price;
  product: Product;
  status: SubscriptionStatusEnum;
};

/** Possible values for the subscription status */
export type SubscriptionStatusEnum =
  /** Active */
  | "ACTIVE"
  /** Canceled */
  | "CANCELED"
  /** Past Due */
  | "PAST_DUE";

/** Available parameters to update an application */
export type UpdateApplicationParams = {
  award?: InputMaybe<UpdateAwardParams>;
  deadline?: InputMaybe<Scalars["Date"]["input"]>;
  deadlineType?: InputMaybe<DeadlineTypeEnum>;
  declined?: InputMaybe<Scalars["Boolean"]["input"]>;
  declinedDetails?: InputMaybe<CreateDeclinedParams>;
  ineligibleReason?: InputMaybe<Scalars["String"]["input"]>;
  reports?: InputMaybe<Array<UpdateReportParams>>;
  submission?: InputMaybe<UpdateSubmissionParams>;
  programId: InputMaybe<Scalars["Int"]["input"]>;
};

/** Available parameters to update an award */
export type UpdateAwardParams = {
  amountAwarded?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  receivementDate?: InputMaybe<Scalars["Date"]["input"]>;
};

/** Input to update a file */
export type UpdateFile = {
  folder?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Available fields to update a finder */
export type UpdateFunderParams = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  howToApply?: InputMaybe<Scalars["String"]["input"]>;
  isPaused?: InputMaybe<Scalars["Boolean"]["input"]>;
  keyContacts?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<UpdateLocationParams>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<FunderTypeEnum>;
  website?: InputMaybe<Scalars["String"]["input"]>;
  complexityScore?: InputMaybe<Scalars["String"]["input"]>;
};

/** Available parameters to update a location */
export type UpdateLocationParams = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  complement?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
  zipcode?: InputMaybe<Scalars["String"]["input"]>;
};

/** Available fields to update an organization */
export type UpdateOrganizationParams = {
  fiscalYearStart?: InputMaybe<Scalars["Date"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Available fields to update a program */
export type UpdateProgramParams = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Update a program's yearly goal */
export type UpdateProgramYearlyGoalParams = {
  goal: Scalars["Decimal"]["input"];
  singleYear?: InputMaybe<Scalars["Boolean"]["input"]>;
  year: Scalars["Int"]["input"];
};

/** Available parameters to update a report */
export type UpdateReportParams = {
  deadline?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  submissionDate?: InputMaybe<Scalars["Date"]["input"]>;
  urgency?: InputMaybe<ReportUrgencyEnum>;
};

/** Available parameters to update a submission */
export type UpdateSubmissionParams = {
  amountRequested?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  submissionDate?: InputMaybe<Scalars["Date"]["input"]>;
};

/** Input to upload a file */
export type UploadFile = {
  file: Scalars["Upload"]["input"];
  folder: Scalars["String"]["input"];
  funderId: Scalars["Int"]["input"];
};

/** An user */
export type User = {
  __typename?: "User";
  authorized?: Maybe<Scalars["Boolean"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  organization?: Maybe<Organization>;
  role?: Maybe<UserRoleEnum>;
  verified?: Maybe<Scalars["Boolean"]["output"]>;
};

/** User roles */
export type UserRoleEnum =
  /** Admin */
  | "ADMIN"
  /** User */
  | "USER"
  /** Manager */
  | "MANAGER";

export type ListImpersonableOrganizationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListImpersonableOrganizationsQuery = {
  __typename?: "RootQueryType";
  listOrganizations?: Array<
    { __typename?: "Organization"; id: number; name: string } & {
      " $fragmentRefs"?: {
        AdminImpersonationFragmentFragment: AdminImpersonationFragmentFragment;
      };
    }
  > | null;
};

export type AdminImpersonationFragmentFragment = {
  __typename?: "Organization";
  id: number;
  currentFiscalYear: number;
} & { " $fragmentName"?: "AdminImpersonationFragmentFragment" };

export type FunderContactInformationFragmentFragment = {
  __typename?: "Funder";
  id: number;
  name: string;
  email?: string | null;
  phone?: string | null;
  type: FunderTypeEnum;
  website?: string | null;
  complexityScore?: string | null;
  keyContacts?: string | null;
} & { " $fragmentName"?: "FunderContactInformationFragmentFragment" };

export type UpdateFunderMutationMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  params: UpdateFunderParams;
}>;

export type UpdateFunderMutationMutation = {
  __typename?: "RootMutationType";
  updateFunder?:
  | ({ __typename?: "Funder" } & {
    " $fragmentRefs"?: {
      FunderContactInformationFragmentFragment: FunderContactInformationFragmentFragment;
    };
  })
  | null;
};

export type FunderLocationInformationFragmentFragment = {
  __typename?: "Funder";
  location?: {
    __typename?: "Location";
    id?: number | null;
    city?: string | null;
    complement?: string | null;
    state?: string | null;
    streetAddress?: string | null;
    zipcode?: string | null;
  } | null;
} & { " $fragmentName"?: "FunderLocationInformationFragmentFragment" };

export type FunderHowToApplyFragmentFragment = {
  __typename?: "Funder";
  id: number;
  howToApply?: string | null;
} & { " $fragmentName"?: "FunderHowToApplyFragmentFragment" };

export type FunderHowToApplyMutationMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  howToApply: Scalars["String"]["input"];
}>;

export type FunderHowToApplyMutationMutation = {
  __typename?: "RootMutationType";
  updateFunder?: {
    __typename?: "Funder";
    id: number;
    howToApply?: string | null;
  } | null;
};

export type FunderProfileNotesFragmentFragment = {
  __typename?: "Funder";
  id: number;
  notes?: string | null;
} & { " $fragmentName"?: "FunderProfileNotesFragmentFragment" };

export type FunderProfileNotesMutationMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  notes: Scalars["String"]["input"];
}>;

export type FunderProfileNotesMutationMutation = {
  __typename?: "RootMutationType";
  updateFunder?: {
    __typename?: "Funder";
    id: number;
    notes?: string | null;
  } | null;
};

export type CreateFunderMutationVariables = Exact<{
  params: CreateFunderParams;
}>;

export type CreateFunderMutation = {
  __typename?: "RootMutationType";
  createFunder?: {
    __typename?: "Funder";
    id: number;
    name: string;
    type?: string;
    email?: string;
    phone?: string;
    keyContacts?: string;
    website?: string;
    location?: {
      city?: string;
      complement?: string;
      state?: string;
      streetAddress?: string;
      zipcode?: string;
    }
  } | null;
};

export type ListProgramAndFunderOptionsQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type ListProgramAndFunderOptionsQuery = {
  __typename?: "RootQueryType";
  listFunders?: Array<{
    __typename?: "Funder";
    id: number;
    name: string;
  }> | null;
  listPrograms?: Array<{
    __typename?: "Program";
    id: number;
    name: string;
  }> | null;
};

export type CreateGrantMutationVariables = Exact<{
  params: CreateGrantParams;
}>;

export type CreateGrantMutation = {
  __typename?: "RootMutationType";
  createGrant?: {
    __typename: "Grant";
    id: number;
    funder: {
      __typename: "Funder";
      organization: { __typename: "Organization"; id: number };
    };
  } | null;
};

export type ListProgramsFragmentFragment = ({
  __typename?: "Program";
  id: number;
  name: string;
  yearlyGoal: string;
  achievedAmount: string;
} & {
  " $fragmentRefs"?: {
    EditProgramGoalFragmentFragment: EditProgramGoalFragmentFragment;
  };
}) & { " $fragmentName"?: "ListProgramsFragmentFragment" };

export type SelectYearFragmentFragment = {
  __typename?: "Organization";
  firstFiscalYear: number;
  currentFiscalYear: number;
} & { " $fragmentName"?: "SelectYearFragmentFragment" };

export type GetCurrentUserQueryQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQueryQuery = {
  __typename?: "RootQueryType";
  currentUser?: {
    __typename?: "User";
    id?: number | null;
    role?: UserRoleEnum | null;
    authorized?: boolean | null;
    organization?:
    | ({ __typename?: "Organization"; id: number; name: string } & {
      " $fragmentRefs"?: {
        AdminImpersonationFragmentFragment: AdminImpersonationFragmentFragment;
      };
    })
    | null;
  } | null;
};

export type CreateProgramMutationVariables = Exact<{
  params: CreateProgramParams;
}>;

export type CreateProgramMutation = {
  __typename?: "RootMutationType";
  createProgram?: { __typename?: "Program"; id: number } | null;
};

export type EditProgramGoalFragmentFragment = {
  __typename?: "Program";
  id: number;
  yearlyGoal: string;
} & { " $fragmentName"?: "EditProgramGoalFragmentFragment" };

export type EditProgramGoalMutationMutationVariables = Exact<{
  programId: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
  goal: Scalars["Decimal"]["input"];
  singleYear?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type EditProgramGoalMutationMutation = {
  __typename?: "RootMutationType";
  updateProgramYearlyGoal?: { __typename?: "Program"; id: number } | null;
};

export type BulletChartFragmentFragment = {
  __typename?: "Organization";
  yearlyGoal: string;
  bcAwards: Array<{ __typename?: "AwardByYear"; awarded: string }>;
} & { " $fragmentName"?: "BulletChartFragmentFragment" };

export type ComparisonChartFragmentFragment = {
  __typename?: "AwardByYear";
  awarded: string;
  year: number;
} & { " $fragmentName"?: "ComparisonChartFragmentFragment" };

export type FunderTypesCountFragmentFragment = {
  __typename?: "FunderTypesCount";
  foundation: number;
  corporate: number;
  government: number;
  other: number;
} & { " $fragmentName"?: "FunderTypesCountFragmentFragment" };

export type PrintDetailsFragmentFragment = {
  __typename?: "Application";
  id: number;
  amountAwarded?: string | null;
  amountRequested?: string | null;
  fiscalDate: string;
  grant: {
    __typename?: "Grant";
    program: { __typename?: "Program"; name: string };
    funder: { __typename?: "Funder"; name: string };
  };
} & { " $fragmentName"?: "PrintDetailsFragmentFragment" };

export type DashboardApplicationsFragmentFragment = ({
  __typename?: "Application";
  status: ApplicationStatusEnum;
} & {
  " $fragmentRefs"?: {
    PrintDetailsFragmentFragment: PrintDetailsFragmentFragment;
  };
}) & { " $fragmentName"?: "DashboardApplicationsFragmentFragment" };

export type DashboardSummaryFragmentFragment = {
  __typename?: "AwardByYear";
  awarded: string;
  awardedCount: number;
  pending: string;
  pendingCount: number;
  requested: string;
  requestedCount: number;
} & { " $fragmentName"?: "DashboardSummaryFragmentFragment" };

export type DashboardQueryQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  currentFy: Scalars["Int"]["input"];
  chartFys: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
}>;

export type DashboardQueryQuery = {
  __typename?: "RootQueryType";
  getOrganization: {
    __typename?: "Organization";
    awardsByYear: Array<
      { __typename?: "AwardByYear" } & {
        " $fragmentRefs"?: {
          ComparisonChartFragmentFragment: ComparisonChartFragmentFragment;
          DashboardSummaryFragmentFragment: DashboardSummaryFragmentFragment;
        };
      }
    >;
    funderTypesCount: { __typename?: "FunderTypesCount" } & {
      " $fragmentRefs"?: {
        FunderTypesCountFragmentFragment: FunderTypesCountFragmentFragment;
      };
    };
  } & {
    " $fragmentRefs"?: {
      SelectYearFragmentFragment: SelectYearFragmentFragment;
      BulletChartFragmentFragment: BulletChartFragmentFragment;
    };
  };
};

export type PrintQueryQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type PrintQueryQuery = {
  __typename?: "RootQueryType";
  listApplications?: Array<
    { __typename?: "Application" } & {
      " $fragmentRefs"?: {
        DashboardApplicationsFragmentFragment: DashboardApplicationsFragmentFragment;
      };
    }
  > | null;
};

export type CreateFolderMutationMutationVariables = Exact<{
  funderId: Scalars["Int"]["input"];
  folder: Scalars["String"]["input"];
}>;

export type CreateFolderMutationMutation = {
  __typename?: "RootMutationType";
  createFolder?: { __typename?: "Folder"; name: string } | null;
};

export type EditFileFragmentFragment = {
  __typename?: "File";
  id: number;
  name: string;
  folder: string;
} & { " $fragmentName"?: "EditFileFragmentFragment" };

export type EditFileMutationMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  folder?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type EditFileMutationMutation = {
  __typename?: "RootMutationType";
  updateFile?: { __typename?: "File"; id: number } | null;
};

export type ListFolderOptionsQueryVariables = Exact<{
  funderId: Scalars["Int"]["input"];
}>;

export type ListFolderOptionsQuery = {
  __typename?: "RootQueryType";
  listFolders?: Array<{ __typename?: "Folder"; name: string }> | null;
};

export type UploadDocumentMutationMutationVariables = Exact<{
  funderId: Scalars["Int"]["input"];
  file: Scalars["Upload"]["input"];
  folder: Scalars["String"]["input"];
}>;

export type UploadDocumentMutationMutation = {
  __typename?: "RootMutationType";
  uploadFile?: { __typename?: "File"; id: number } | null;
};

export type FileRowFragmentFragment = ({
  __typename?: "File";
  id: number;
  name: string;
  insertedAt: string;
  updatedAt: string;
  signedUrl: string;
} & {
  " $fragmentRefs"?: { EditFileFragmentFragment: EditFileFragmentFragment };
}) & { " $fragmentName"?: "FileRowFragmentFragment" };

export type DeleteFileMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type DeleteFileMutation = {
  __typename?: "RootMutationType";
  deleteFile?: { __typename?: "File"; id: number } | null;
};

export type FolderRowFragmentFragment = {
  __typename?: "Folder";
  name: string;
} & { " $fragmentName"?: "FolderRowFragmentFragment" };

export type DeleteFolderMutationVariables = Exact<{
  folder: Scalars["String"]["input"];
  funderId: Scalars["Int"]["input"];
}>;

export type DeleteFolderMutation = {
  __typename?: "RootMutationType";
  deleteFolder: Array<{ __typename?: "File"; id: number }>;
};

export type GetFunderFolderQueryVariables = Exact<{
  funderId: Scalars["Int"]["input"];
  folder: Scalars["String"]["input"];
  search: Scalars["String"]["input"];
}>;

export type GetFunderFolderQuery = {
  __typename?: "RootQueryType";
  getFolder?: {
    __typename?: "Folder";
    name: string;
    children: Array<
      | ({ __typename: "File"; id: number } & {
        " $fragmentRefs"?: {
          FileRowFragmentFragment: FileRowFragmentFragment;
        };
      })
      | ({ __typename: "Folder"; name: string } & {
        " $fragmentRefs"?: {
          FolderRowFragmentFragment: FolderRowFragmentFragment;
        };
      })
    >;
  } | null;
};

export type FunderNextGrantsFragmentFragment = {
  __typename?: "Funder";
  grants: Array<{
    __typename?: "Grant";
    frequency: GrantFrequencyEnum;
    program: { __typename?: "Program"; id: number; name: string };
    applications: Array<{
      __typename?: "Application";
      id: number;
      deadline: string;
      status: ApplicationStatusEnum;
    }>;
  }>;
} & { " $fragmentName"?: "FunderNextGrantsFragmentFragment" };

export type FunderPreviousGrantsFragmentFragment = {
  __typename?: "Funder";
  id: number;
  name: string;
  totalAwardAmount?: string | null;
  grants: Array<{
    __typename?: "Grant";
    program: { __typename?: "Program"; id: number; name: string };
    applications: Array<{
      __typename?: "Application";
      id: number;
      deadline: string;
      status: ApplicationStatusEnum;
      award?: {
        __typename?: "Award";
        amountAwarded: string;
        receivementDate: string;
        responseDays?: number | null;
      } | null;
      declinedDetails?: {
        __typename?: "DeclinedDetails";
        declinedDate: string;
      } | null;
      submission?: {
        __typename?: "Submission";
        submissionDate: string;
        amountRequested: string;
      } | null;
    }>;
  }>;
} & { " $fragmentName"?: "FunderPreviousGrantsFragmentFragment" };

export type GetFunderProfileQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type GetFunderProfileQuery = {
  __typename?: "RootQueryType";
  getFunder?:
  | ({
    __typename?: "Funder";
    id: number;
    name: string;
    isPaused: boolean;
    complexityScore?: string | null;
    notes?: string | null;
    howToApply?: string | null;
  } & {
    " $fragmentRefs"?: {
      FunderProfileNotesFragmentFragment: FunderProfileNotesFragmentFragment;
      FunderHowToApplyFragmentFragment: FunderHowToApplyFragmentFragment;
      FunderContactInformationFragmentFragment: FunderContactInformationFragmentFragment;
      FunderPreviousGrantsFragmentFragment: FunderPreviousGrantsFragmentFragment;
      FunderNextGrantsFragmentFragment: FunderNextGrantsFragmentFragment;
      FunderLocationInformationFragmentFragment: FunderLocationInformationFragmentFragment;
    };
  })
  | null;
};

export type ListFundersQueryQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  search?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ListFundersQueryQuery = {
  __typename?: "RootQueryType";
  listFunders?: Array<{
    __typename?: "Funder";
    id: number;
    name: string;
    email?: string | null;
    type: FunderTypeEnum;
    website?: string | null;
    totalAwardAmount?: string | null;
  }> | null;
};

export type ListApplicationDeadlinesQueryVariables = Exact<{
  filters: FilterApplicationParams;
  pagination?: InputMaybe<PaginationParams>;
}>;

export type ListApplicationDeadlinesQuery = {
  __typename?: "RootQueryType";
  listApplications?: Array<{
    __typename?: "Application";
    id: number;
    status: ApplicationStatusEnum;
    isSubmittedAsIneligible: boolean;
    ineligibleReason?: string | null;
    deadline: string;
    deadlineType: DeadlineTypeEnum;
    declined: boolean;
    award?: {
      __typename?: "Award";
      id: number;
      amountAwarded: string;
      receivementDate: string;
    } | null;
    grant: {
      __typename?: "Grant";
      id: number;
      frequency: string;
      responseAverage?: number | null;
      program: { __typename?: "Program"; id: number; name: string };
      funder: {
        __typename?: "Funder";
        id: number;
        name: string;
        lastAward?: {
          __typename?: "Award";
          id: number;
          receivementDate: string;
        } | null;
        complexityScore: string | null;
      };
    };
    declinedDetails?: {
      __typename?: "DeclinedDetails";
      declinedDate: string;
      declinedReason?: string | null;
    } | null;
    submission?: {
      __typename?: "Submission";
      id: number;
      submissionDate: string;
      amountRequested: string;
    } | null;
    reports: Array<{
      __typename?: "Report";
      id: number;
      urgency: ReportUrgencyEnum;
      deadline: string;
    }>;
  }> | null;
};

export type GetLargestFundersQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  startAt: Scalars["Date"]["input"];
  endAt: Scalars["Date"]["input"];
  type?: InputMaybe<FunderTypeEnum>;
}>;

export type GetLargestFundersQuery = {
  __typename?: "RootQueryType";
  getOrganization: {
    __typename?: "Organization";
    id: number;
    oldestAward?: {
      __typename?: "Award";
      id: number;
      receivementDate: string;
    } | null;
    programs: Array<{ __typename?: "Program"; id: number; name: string }>;
    largestFunders: {
      __typename?: "LargestFunders";
      overall: Array<{
        __typename?: "Funder";
        id: number;
        name: string;
        totalAwardAmount?: string | null;
      }>;
      programsFunders: Array<{
        __typename?: "ProgramsFunders";
        funders: Array<{
          __typename?: "ProgramFunder";
          awardedAmount: string;
          funder: { __typename?: "Funder"; id: number; name: string };
        }>;
        program: { __typename?: "Program"; id: number; name: string };
      }>;
    };
  };
};

export type ListProgramsQueryQueryVariables = Exact<{
  orgId: Scalars["Int"]["input"];
  fy: Scalars["Int"]["input"];
}>;

export type ListProgramsQueryQuery = {
  __typename?: "RootQueryType";
  getOrganization: {
    __typename?: "Organization";
    currentFiscalYear: number;
    programs: Array<
      { __typename?: "Program" } & {
        " $fragmentRefs"?: {
          ListProgramsFragmentFragment: ListProgramsFragmentFragment;
        };
      }
    >;
  } & {
    " $fragmentRefs"?: {
      SelectYearFragmentFragment: SelectYearFragmentFragment;
    };
  };
};

export const AdminImpersonationFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdminImpersonationFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "currentFiscalYear" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminImpersonationFragmentFragment, unknown>;
export const FunderContactInformationFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FunderContactInformationFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Funder" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "website" } },
          { kind: "Field", name: { kind: "Name", value: "complexityScore" } },
          { kind: "Field", name: { kind: "Name", value: "keyContacts" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FunderContactInformationFragmentFragment, unknown>;
export const FunderLocationInformationFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FunderLocationInformationFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Funder" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "location" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "city" } },
                { kind: "Field", name: { kind: "Name", value: "complement" } },
                { kind: "Field", name: { kind: "Name", value: "state" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "zipcode" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FunderLocationInformationFragmentFragment,
  unknown
>;
export const FunderHowToApplyFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FunderHowToApplyFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Funder" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "howToApply" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FunderHowToApplyFragmentFragment, unknown>;
export const FunderProfileNotesFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FunderProfileNotesFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Funder" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "notes" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FunderProfileNotesFragmentFragment, unknown>;
export const EditProgramGoalFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EditProgramGoalFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Program" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "yearlyGoal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fiscalYear" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fy" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditProgramGoalFragmentFragment, unknown>;
export const ListProgramsFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListProgramsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Program" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "yearlyGoal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fiscalYear" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fy" },
                },
              },
            ],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "achievedAmount" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fiscalYear" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fy" },
                },
              },
            ],
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "EditProgramGoalFragment" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EditProgramGoalFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Program" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "yearlyGoal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fiscalYear" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fy" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListProgramsFragmentFragment, unknown>;
export const SelectYearFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SelectYearFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "firstFiscalYear" } },
          { kind: "Field", name: { kind: "Name", value: "currentFiscalYear" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SelectYearFragmentFragment, unknown>;
export const BulletChartFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BulletChartFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "yearlyGoal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fiscalYear" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "currentFy" },
                },
              },
            ],
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "bcAwards" },
            name: { kind: "Name", value: "awardsByYear" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fiscalYears" },
                value: {
                  kind: "ListValue",
                  values: [
                    {
                      kind: "Variable",
                      name: { kind: "Name", value: "currentFy" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "awarded" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulletChartFragmentFragment, unknown>;
export const ComparisonChartFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ComparisonChartFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AwardByYear" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "awarded" } },
          { kind: "Field", name: { kind: "Name", value: "year" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ComparisonChartFragmentFragment, unknown>;
export const FunderTypesCountFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FunderTypesCountFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FunderTypesCount" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "foundation" } },
          { kind: "Field", name: { kind: "Name", value: "corporate" } },
          { kind: "Field", name: { kind: "Name", value: "government" } },
          { kind: "Field", name: { kind: "Name", value: "other" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FunderTypesCountFragmentFragment, unknown>;
export const PrintDetailsFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PrintDetailsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Application" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "grant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "program" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "funder" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "amountAwarded" } },
          { kind: "Field", name: { kind: "Name", value: "amountRequested" } },
          { kind: "Field", name: { kind: "Name", value: "fiscalDate" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrintDetailsFragmentFragment, unknown>;
export const DashboardApplicationsFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DashboardApplicationsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Application" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "PrintDetailsFragment" },
          },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PrintDetailsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Application" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "grant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "program" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "funder" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "amountAwarded" } },
          { kind: "Field", name: { kind: "Name", value: "amountRequested" } },
          { kind: "Field", name: { kind: "Name", value: "fiscalDate" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardApplicationsFragmentFragment, unknown>;
export const DashboardSummaryFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DashboardSummaryFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AwardByYear" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "awarded" } },
          { kind: "Field", name: { kind: "Name", value: "awardedCount" } },
          { kind: "Field", name: { kind: "Name", value: "pending" } },
          { kind: "Field", name: { kind: "Name", value: "pendingCount" } },
          { kind: "Field", name: { kind: "Name", value: "requested" } },
          { kind: "Field", name: { kind: "Name", value: "requestedCount" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardSummaryFragmentFragment, unknown>;
export const EditFileFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EditFileFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "File" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "folder" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditFileFragmentFragment, unknown>;
export const FileRowFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FileRowFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "File" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "signedUrl" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "EditFileFragment" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EditFileFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "File" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "folder" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileRowFragmentFragment, unknown>;
export const FolderRowFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FolderRowFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Folder" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
      },
    },
  ],
} as unknown as DocumentNode<FolderRowFragmentFragment, unknown>;
export const FunderNextGrantsFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FunderNextGrantsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Funder" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "grants" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "frequency" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "program" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applications" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deadline" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FunderNextGrantsFragmentFragment, unknown>;
export const FunderPreviousGrantsFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FunderPreviousGrantsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Funder" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "grants" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "program" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applications" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deadline" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "award" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountAwarded" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "receivementDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "responseDays" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "declinedDetails" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "declinedDate" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "submission" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "submissionDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountRequested" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "totalAwardAmount" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FunderPreviousGrantsFragmentFragment, unknown>;
export const ListImpersonableOrganizationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListImpersonableOrganizations" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listOrganizations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AdminImpersonationFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdminImpersonationFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "currentFiscalYear" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListImpersonableOrganizationsQuery,
  ListImpersonableOrganizationsQueryVariables
>;
export const UpdateFunderMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateFunderMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "params" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateFunderParams" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateFunder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "params" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "params" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "FunderContactInformationFragment",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FunderContactInformationFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Funder" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "website" } },
          { kind: "Field", name: { kind: "Name", value: "complexityScore" } },
          { kind: "Field", name: { kind: "Name", value: "keyContacts" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFunderMutationMutation,
  UpdateFunderMutationMutationVariables
>;
export const FunderHowToApplyMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FunderHowToApplyMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "howToApply" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateFunder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "params" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "howToApply" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "howToApply" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "howToApply" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FunderHowToApplyMutationMutation,
  FunderHowToApplyMutationMutationVariables
>;
export const FunderProfileNotesMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FunderProfileNotesMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "notes" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateFunder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "params" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "notes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "notes" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FunderProfileNotesMutationMutation,
  FunderProfileNotesMutationMutationVariables
>;
export const CreateFunderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateFunder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "params" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateFunderParams" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createFunder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "params" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "params" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "keyContacts" } },
                { kind: "Field", name: { kind: "Name", value: "website" } },
                { 
                  kind: "Field", 
                  name: { kind: "Name", value: "location" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      { kind: "Field", name: { kind: "Name", value: "complement" } },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      { kind: "Field", name: { kind: "Name", value: "streetAddress" } },
                      { kind: "Field", name: { kind: "Name", value: "zipcode" } },
                    ]
                  }
                }
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFunderMutation,
  CreateFunderMutationVariables
>;
export const ListProgramAndFunderOptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListProgramAndFunderOptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listFunders" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listPrograms" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListProgramAndFunderOptionsQuery,
  ListProgramAndFunderOptionsQueryVariables
>;
export const CreateGrantDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateGrant" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "params" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateGrantParams" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createGrant" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "params" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "params" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "funder" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organization" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateGrantMutation, CreateGrantMutationVariables>;
export const GetCurrentUserQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCurrentUserQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "role" } },
                { kind: "Field", name: { kind: "Name", value: "authorized" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "AdminImpersonationFragment",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdminImpersonationFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "currentFiscalYear" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCurrentUserQueryQuery,
  GetCurrentUserQueryQueryVariables
>;
export const CreateProgramDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateProgram" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "params" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateProgramParams" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createProgram" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "params" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "params" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProgramMutation,
  CreateProgramMutationVariables
>;
export const EditProgramGoalMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "EditProgramGoalMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "programId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "year" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "goal" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Decimal" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "singleYear" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateProgramYearlyGoal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "programId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "params" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "goal" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "goal" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "year" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "year" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "singleYear" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "singleYear" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditProgramGoalMutationMutation,
  EditProgramGoalMutationMutationVariables
>;
export const DashboardQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DashboardQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currentFy" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "chartFys" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "Int" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getOrganization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SelectYearFragment" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "awardsByYear" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "fiscalYears" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "chartFys" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ComparisonChartFragment",
                        },
                      },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "DashboardSummaryFragment",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "funderTypesCount" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "fiscalYear" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "currentFy" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "FunderTypesCountFragment",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "BulletChartFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SelectYearFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "firstFiscalYear" } },
          { kind: "Field", name: { kind: "Name", value: "currentFiscalYear" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ComparisonChartFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AwardByYear" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "awarded" } },
          { kind: "Field", name: { kind: "Name", value: "year" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DashboardSummaryFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AwardByYear" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "awarded" } },
          { kind: "Field", name: { kind: "Name", value: "awardedCount" } },
          { kind: "Field", name: { kind: "Name", value: "pending" } },
          { kind: "Field", name: { kind: "Name", value: "pendingCount" } },
          { kind: "Field", name: { kind: "Name", value: "requested" } },
          { kind: "Field", name: { kind: "Name", value: "requestedCount" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FunderTypesCountFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FunderTypesCount" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "foundation" } },
          { kind: "Field", name: { kind: "Name", value: "corporate" } },
          { kind: "Field", name: { kind: "Name", value: "government" } },
          { kind: "Field", name: { kind: "Name", value: "other" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BulletChartFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "yearlyGoal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fiscalYear" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "currentFy" },
                },
              },
            ],
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "bcAwards" },
            name: { kind: "Name", value: "awardsByYear" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fiscalYears" },
                value: {
                  kind: "ListValue",
                  values: [
                    {
                      kind: "Variable",
                      name: { kind: "Name", value: "currentFy" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "awarded" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardQueryQuery, DashboardQueryQueryVariables>;
export const PrintQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PrintQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listApplications" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "DashboardApplicationsFragment",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PrintDetailsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Application" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "grant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "frequency" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "program" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "funder" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "amountAwarded" } },
          { kind: "Field", name: { kind: "Name", value: "amountRequested" } },
          { kind: "Field", name: { kind: "Name", value: "fiscalDate" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DashboardApplicationsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Application" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "PrintDetailsFragment" },
          },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrintQueryQuery, PrintQueryQueryVariables>;
export const CreateFolderMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateFolderMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "funderId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "folder" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createFolder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "funderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "funderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "folder" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "folder" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFolderMutationMutation,
  CreateFolderMutationMutationVariables
>;
export const EditFileMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "EditFileMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "folder" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateFile" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "params" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "name" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "folder" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "folder" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditFileMutationMutation,
  EditFileMutationMutationVariables
>;
export const ListFolderOptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListFolderOptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "funderId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listFolders" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "funderId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "funderId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListFolderOptionsQuery,
  ListFolderOptionsQueryVariables
>;
export const UploadDocumentMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UploadDocumentMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "funderId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "file" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Upload" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "folder" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "uploadFile" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "params" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "funderId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "funderId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "file" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "file" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "folder" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "folder" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadDocumentMutationMutation,
  UploadDocumentMutationMutationVariables
>;
export const DeleteFileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteFile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteFile" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFileMutation, DeleteFileMutationVariables>;
export const DeleteFolderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteFolder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "folder" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "funderId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteFolder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "folder" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "folder" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "funderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "funderId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFolderMutation,
  DeleteFolderMutationVariables
>;
export const GetFunderFolderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getFunderFolder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "funderId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "folder" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "search" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getFolder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "funderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "funderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "folder" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "folder" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "children" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "search" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "search" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Folder" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "FolderRowFragment",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "File" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "FileRowFragment" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EditFileFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "File" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "folder" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FolderRowFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Folder" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FileRowFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "File" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "signedUrl" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "EditFileFragment" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFunderFolderQuery,
  GetFunderFolderQueryVariables
>;
export const GetFunderProfileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFunderProfile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getFunder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "isPaused" } },
                { kind: "Field", name: { kind: "Name", value: "complexityScore" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                { kind: "Field", name: { kind: "Name", value: "howToApply" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FunderProfileNotesFragment" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FunderHowToApplyFragment" },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "FunderContactInformationFragment",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FunderPreviousGrantsFragment" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FunderNextGrantsFragment" },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "FunderLocationInformationFragment",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FunderProfileNotesFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Funder" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "notes" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FunderHowToApplyFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Funder" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "howToApply" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FunderContactInformationFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Funder" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "website" } },
          { kind: "Field", name: { kind: "Name", value: "complexityScore" } },
          { kind: "Field", name: { kind: "Name", value: "keyContacts" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FunderPreviousGrantsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Funder" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "grants" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "program" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applications" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deadline" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "award" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountAwarded" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "receivementDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "responseDays" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "declinedDetails" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "declinedDate" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "submission" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "submissionDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountRequested" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "totalAwardAmount" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FunderNextGrantsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Funder" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "grants" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "frequency" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "program" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applications" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deadline" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FunderLocationInformationFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Funder" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "location" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "city" } },
                { kind: "Field", name: { kind: "Name", value: "complement" } },
                { kind: "Field", name: { kind: "Name", value: "state" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "zipcode" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFunderProfileQuery,
  GetFunderProfileQueryVariables
>;
export const ListFundersQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListFundersQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "search" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listFunders" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "search" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "search" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "website" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalAwardAmount" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListFundersQueryQuery,
  ListFundersQueryQueryVariables
>;
export const ListApplicationDeadlinesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListApplicationDeadlines" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "FilterApplicationParams" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pagination" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PaginationParams" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listApplications" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pagination" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "award" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountAwarded" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivementDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "grant" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "frequency" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "program" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "funder" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastAward" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "receivementDate",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "complexityScore" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "responseAverage" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "isSubmittedAsIneligible" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ineligibleReason" },
                },
                { kind: "Field", name: { kind: "Name", value: "deadline" } },
                { kind: "Field", name: { kind: "Name", value: "deadlineType" } },
                { kind: "Field", name: { kind: "Name", value: "declined" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "declinedDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "declinedDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "declinedReason" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "submission" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "submissionDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountRequested" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reports" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "urgency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deadline" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListApplicationDeadlinesQuery,
  ListApplicationDeadlinesQueryVariables
>;
export const GetLargestFundersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getLargestFunders" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startAt" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endAt" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "FunderTypeEnum" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getOrganization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "oldestAward" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivementDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "programs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "largestFunders" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "startAt" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startAt" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "endAt" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "endAt" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "type" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "overall" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalAwardAmount" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "startAt" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "startAt" },
                                  },
                                },
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "endAt" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "endAt" },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "programsFunders" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "funders" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "funder" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "awardedAmount",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "program" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLargestFundersQuery,
  GetLargestFundersQueryVariables
>;
export const ListProgramsQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListProgramsQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orgId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "fy" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getOrganization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orgId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentFiscalYear" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SelectYearFragment" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "programs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListProgramsFragment" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EditProgramGoalFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Program" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "yearlyGoal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fiscalYear" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fy" },
                },
              },
            ],
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SelectYearFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "firstFiscalYear" } },
          { kind: "Field", name: { kind: "Name", value: "currentFiscalYear" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListProgramsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Program" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "yearlyGoal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fiscalYear" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fy" },
                },
              },
            ],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "achievedAmount" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fiscalYear" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fy" },
                },
              },
            ],
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "EditProgramGoalFragment" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListProgramsQueryQuery,
  ListProgramsQueryQueryVariables
>;
