import yup from "settings/yup";

const login2FASchema = yup.object().shape({
    email: yup.string(),
    password: yup.string(),
    code: yup.string().required().matches(/^\d{5}$/, 'Code must be exactly 5 digits')
});

export interface Login2FASchema {
    email: string;
    password: string;
    code: string
}

export default login2FASchema;
