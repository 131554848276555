import yup from "settings/yup";

const create2FASchema = yup.object().shape({
    email_for_2fa: yup.string().required(),
    code: yup.string().default("")
});

export interface Create2FASchema {
    email_for_2fa: string;
    code: string
}

export default create2FASchema;
