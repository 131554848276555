import React from "react";

import UpdateButtonCell from "components/Table/TableCell/CellValue/UpdateButtonCell";
import i18n from "translations/i18n";
import {
  GrantCell,
  FunderCell,
} from "components/Table/TableCell/CellValue/GrantCell";

export enum TableTitlesEnum {
  // Id = "id",
  ApplicationDeadline = "applicationDeadline",

  // Frequency = "frequency",
  DeadlineType = "deadlineType", 

  Funder = "funder",

  ComplexityScore = "complexityScore",

  LastAward = "lastAward",

  DateSubmitted = "dateSubmitted",

  AmountRequested = "amountRequested",

  Program = "program",

  DateReceived = "dateReceived",

  AmountAwarded = "amountAwarded",

  DeclinedDate = "declinedDate",

  ResponseAverage = "responseAverage",

  Status = "status",
}

export interface ColumnContent {
  label: string;
  field: string;
  component?: React.FC;
  componentProps?: Record<string, string>;
}

const grantFlowTableColumns: Record<TableTitlesEnum, ColumnContent> = {
  // [TableTitlesEnum.Id]: {
  //   label: "the id",
  //   field: TableTitlesEnum.Id,
  //   component: GrantCell,
  // },
  [TableTitlesEnum.ApplicationDeadline]: {
    label: i18n.t("tables.columns_titles.application_deadline"),
    field: TableTitlesEnum.ApplicationDeadline,
    component: GrantCell
  },
  // [TableTitlesEnum.Frequency]: {
  //   label: i18n.t("tables.columns_titles.frequency"),
  //   field: TableTitlesEnum.Frequency,
  //   component: GrantCell,
  // },
  [TableTitlesEnum.DeadlineType]: {
    label: i18n.t("tables.columns_titles.deadlineType"),
    field: TableTitlesEnum.DeadlineType,
    component: GrantCell,
  },
  [TableTitlesEnum.Funder]: {
    label: i18n.t("tables.columns_titles.funder"),
    field: TableTitlesEnum.Funder,
    component: FunderCell,
  },
  [TableTitlesEnum.ComplexityScore]: {
    label: i18n.t("tables.columns_titles.complexity_score"),
    field: TableTitlesEnum.ComplexityScore,
    component: GrantCell,
  },
  [TableTitlesEnum.LastAward]: {
    label: i18n.t("tables.columns_titles.last_award"),
    field: TableTitlesEnum.LastAward,
    component: GrantCell,
  },
  [TableTitlesEnum.DateSubmitted]: {
    label: i18n.t("tables.columns_titles.date_submitted"),
    field: TableTitlesEnum.DateSubmitted,
    component: GrantCell,
  },
  [TableTitlesEnum.AmountRequested]: {
    label: i18n.t("tables.columns_titles.amount_requested"),
    field: TableTitlesEnum.AmountRequested,
    component: GrantCell,
  },
  [TableTitlesEnum.Program]: {
    label: i18n.t("tables.columns_titles.program"),
    field: TableTitlesEnum.Program,
    component: GrantCell,
  },
  [TableTitlesEnum.DateReceived]: {
    label: i18n.t("tables.columns_titles.date_received"),
    field: TableTitlesEnum.DateReceived,
    component: GrantCell,
  },
  [TableTitlesEnum.AmountAwarded]: {
    label: i18n.t("tables.columns_titles.amount_awarded"),
    field: TableTitlesEnum.AmountAwarded,
    component: GrantCell,
  },
  [TableTitlesEnum.DeclinedDate]: {
    label: i18n.t("tables.columns_titles.date_declined"),
    field: TableTitlesEnum.DeclinedDate,
    component: GrantCell,
  },
  [TableTitlesEnum.ResponseAverage]: {
    label: i18n.t("tables.columns_titles.response_average"),
    field: TableTitlesEnum.ResponseAverage,
    component: GrantCell,
  },
  [TableTitlesEnum.Status]: {
    label: i18n.t("tables.columns_titles.status"),
    field: TableTitlesEnum.Status,
    component: UpdateButtonCell,
  },
};

export const filteredGrantFlowFormattedColumns = (hiddenColumns: TableTitlesEnum[]) => {
  const keys = Object.keys(grantFlowTableColumns) as TableTitlesEnum[];

  return keys.filter((key) => hiddenColumns.indexOf(key) <= -1).map((key) => ({
    label: grantFlowTableColumns[key].label as string,
    field: grantFlowTableColumns[key].field as string,
    component: grantFlowTableColumns[key].component,
    componentProps: grantFlowTableColumns[key].componentProps,
  }));
}

/**
 * Defines grantFlow table titles.
 */
export const grantFlowFormattedColumns = filteredGrantFlowFormattedColumns([
  TableTitlesEnum.DateReceived,
  TableTitlesEnum.DeclinedDate,
  TableTitlesEnum.AmountAwarded
]);
